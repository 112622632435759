import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import es from './locales/es.json'
import getBrowserLocale from './get-browser-locale'

Vue.use(VueI18n)

function loadTranslations() {
  const locales = [{ en }, { es }]
  const messages = {}
  locales.forEach(lang => {
    const key = Object.keys(lang)
    messages[key] = lang[key]
  })
  return messages
}

function getLocale() {
  let locale = 'es'
  const storageLocale = localStorage.getItem('lang')
  if (storageLocale) locale = storageLocale
  else {
    locale = getBrowserLocale({ countryCodeOnly: true })
    localStorage.setItem('lang', locale)
  }
  return locale
}

export default new VueI18n({
  locale: getLocale(),
  fallbackLocale: 'es',
  messages: loadTranslations(),
  silentTranslationWarn: true,
})
