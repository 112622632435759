<!-- App Main Structure -->
<template>

  <div id="wrapper">
    <!--Header Frontend Panel -->
    <app-header />

    <!-- Main Container -->
    <router-view />

    <!-- Footer Frontend Panel -->
    <app-footer :logo="data.bannerImage" />

  </div>

</template>

<script>
import Header from 'Components/Header/Header.vue'
import Footer from 'Components/Footer/Footer.vue'

import MyData from 'Data/dashboardone.json'

export default {
  components: {
    appHeader: Header,
    appFooter: Footer,
  },
  data() {
    return {
      url: this.$route.path,
      data: MyData.data,
    }
  },
  created() {
    window.addEventListener('scroll', this.onScrollEvent)
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScrollEvent)
  },
  methods: {
    onScrollEvent() {
      const scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0
      if (scrollTop >= 100) {
        document.getElementById('wrapper').classList.add('header-fixed')
      } else {
        document.getElementById('wrapper').classList.remove('header-fixed')
      }
    },
  },
}
</script>
