import Layout from '@/layouts/Layout.vue'
import i18n from '@/lib/i18n'

// dashboard components
const Home = () => import('Views/homePage/Home.vue')
const Solutions = () => import('Views/solutions/Solutions.vue')
const AboutUs = () => import('Views/aboutUs/AboutUs.vue')
const Contact = () => import('Views/contact/Contact.vue')
const Privacy = () => import('Views/policies/Privacy.vue')
const Cookies = () => import('Views/policies/Cookies.vue')
const LegalTerms = () => import('Views/policies/LegalTerms.vue')
const QualityEnvironment = () => import('Views/commitments/QualityEnvironment.vue')
const Security = () => import('Views/commitments/Security.vue')
const Innovation = () => import('Views/commitments/Innovation.vue')
const Equality = () => import('Views/commitments/Equality.vue')
const Posts = () => import('Views/posts/Posts.vue')
const OnePost = () => import('Views/posts/OnePost.vue')
const Error404 = () => import('Views/errors/Error404.vue')
const Error503 = () => import('Views/errors/Error503.vue')
const Turnouts = () => import('Views/imas/Turnouts.vue')
const Auscultation = () => import('Views/imas/Auscultation.vue')
const RailVehicle = () => import('Views/imas/RailVehicle.vue')

export default {
  path: '/',
  component: Layout,
  redirect: '/home',
  children: [
    {
      name: 'home',
      path: '/home',
      component: Home,
      meta: {
        title: i18n.t('routesTitle.home'),
      },
    },
    {
      name: 'solutions',
      path: '/solutions',
      component: Solutions,
      meta: {
        title: i18n.t('routesTitle.solutions'),
      },
    },
    {
      name: 'aboutUs',
      path: '/aboutus',
      component: AboutUs,
      meta: {
        title: i18n.t('routesTitle.aboutUs'),
      },
    },
    {
      name: 'posts',
      path: '/posts',
      component: Posts,
      meta: {
        title: i18n.t('routesTitle.posts'),
      },
    },
    {
      name: 'contact',
      path: '/contact',
      component: Contact,
      meta: {
        title: i18n.t('routesTitle.contact'),
      },
    },
    {
      name: 'privacy',
      path: '/privacy',
      component: Privacy,
      meta: {
        title: i18n.t('routesTitle.privacy'),
      },
    },
    {
      name: 'cookies',
      path: '/cookies',
      component: Cookies,
      meta: {
        title: i18n.t('routesTitle.cookies'),
      },
    },
    {
      name: 'legalTerms',
      path: '/legalTerms',
      component: LegalTerms,
      meta: {
        title: i18n.t('routesTitle.legalTerms'),
      },
    },
    {
      name: 'quality-environment',
      path: '/quality-environment',
      component: QualityEnvironment,
      meta: {
        title: i18n.t('routesTitle.quality'),
      },
    },
    {
      name: 'security',
      path: '/security',
      component: Security,
      meta: {
        title: i18n.t('routesTitle.security'),
      },
    },
    {
      name: 'innovation',
      path: '/innovation',
      component: Innovation,
      meta: {
        title: i18n.t('routesTitle.innovation'),
      },
    },
    {
      name: 'equality',
      path: '/equality',
      component: Equality,
      meta: {
        title: i18n.t('routesTitle.equality'),
      },
    },
    {
      name: 'onePost',
      path: '/onePost/:idPost?',
      component: OnePost,
      meta: {
        title: i18n.t('routesTitle.post'),
      },
    },
    {
      name: 'error404',
      path: '/error404',
      component: Error404,
      meta: {
        title: 'Error 404 - Limmat',
      },
    },
    {
      name: 'error503',
      path: '/error503',
      component: Error503,
      meta: {
        title: 'Error 503 - Limmat',
      },
    },
    {
      name: 'turnouts',
      path: '/turnouts',
      component: Turnouts,
      meta: {
        title: i18n.t('routesTitle.turnouts'),
      },
    },
    {
      name: 'auscultation',
      path: '/auscultation',
      component: Auscultation,
      meta: {
        title: i18n.t('routesTitle.auscultation'),
      },
    },
    {
      name: 'railVehicle',
      path: '/railVehicle',
      component: RailVehicle,
      meta: {
        title: i18n.t('routesTitle.railVehicle'),
      },
    },
  ],
}
