<!-- Footer Structure -->
<template>
  <div
    id="footer"
    class="sticky-footer pt-5"
    style="background-color: black;"
  >
    <!-- Main -->
    <div class="container">
      <b-row>
        <!-- Logo Limmat -->
        <b-col
          cols="12"
          class="col-lg-6 d-flex align-items-center justify-content-center"
        >

          <b-img-lazy
            class="footer-logo"
            src="/static/limmat-images/logos/limmatLogoLetrasBlancas.png"
            width="320"
            height="80"
            alt="Limmat Logo"
          />

        </b-col>

        <!-- Contact -->
        <b-col
          cols="12"
          class="col-lg-6 mt-5"
        >
          <h3
            class="mb-4 text-center"
            style="color:#ff8f00 !important"
          >
            {{ $t('help.contact') }}
          </h3>

          <!-- Adress -->
          <p class="text-center">
            <i
              class="fa fa-home mr-2"
              style="font-size:25px; color:#ff8f00"
            />
            Edificio Zafiro, Calle Gremios Segovianos, 3 (Polígono de Hontoria) CP40195 Segovia
          </p>

          <!-- Phone Number -->
          <p class="text-center">
            <i
              class="fa fa-phone mr-2"
              style="font-size:25px; color:#ff8f00"
            />
            (+34) 644121775
          </p>

          <!--Email Adress -->
          <p class="text-center">
            <i
              class="fa fa-envelope mr-2"
              style="font-size:25px; color:#ff8f00"
            />

            <a
              style="color: white;"
              @click="mailto()"
            >
              <span> info@limmat-group.com</span>
            </a>

          </p>
        </b-col>
      </b-row>

      <b-row>

        <!-- Copyright -->
        <b-col
          cols="12"
          class="mt-5"
        >
          <div class="copyrights text-white">
            <b-row>

              <!-- Policity Links -->
              <b-col
                cols="12"
                lg="5"
                md="5"
                class="my-3"
              >

                <router-link
                  to="/privacy"
                  style="color:#ff8f00;"
                  class="mr-3"
                >
                  {{ $t('privacy.title') }}
                </router-link>

                <router-link
                  to="/legalTerms"
                  style="color:#ff8f00;"
                  class="mr-3"
                >
                  {{ $t('policy.linkTitle') }}
                </router-link>

                <router-link
                  to="/cookies"
                  style="color:#ff8f00;"
                  class="mr-3"
                >
                  {{ $t('cookies.title') }}
                </router-link>

                <span class="d-block w-100 mt-3">{{ $t('copyright.title', { year }) }}</span>
              </b-col>

              <!-- Social Media -->
              <b-col
                class="d-flex align-items-center justify-content-md-end justify-content-center my-3"
                cols="12"
                lg="6"
                md="6"
              >
                <a
                  class="btn btn-primary btn-icon-only rounded-circle"
                  href="https://twitter.com/Limmat_Group"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="fa fa-twitter text-white" />
                </a>
                <a
                  class="btn btn-primary btn-icon-only rounded-circle"
                  href="https://www.linkedin.com/company/limmat-group"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="fa fa-linkedin text-white" />
                </a>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import {
  BRow,
  BCol,
  BImgLazy,
} from 'bootstrap-vue'

export default {
  name: 'Footer',
  components: {
    BRow,
    BCol,
    BImgLazy,
  },
  props: {
    logo: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      year: moment().year(),
    }
  },
  methods: {
    mailto() {
      window.open('mailto:info@limmat-group.com', '_blank')
    },
  },
}
</script>

<style scoped>

  p {
    color: white !important;
  }

</style>
