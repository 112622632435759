<!-- Header Structure -->
<template>
  <nav
    id="navigation"
    class="style-1"
  >

    <ul id="responsive">
      <li
        v-for="menuitem in menuItems"
        :key="menuitem.name"
        :class="{'open-menu': selectedMenu == menuitem.name}"
      >
        <router-link
          v-if="menuitem.type === 'link'"
          class="current"
          :to="{ name: menuitem.state}"
          data-toggle="collapse"
          data-target="#navbar_global"
          aria-controls="navbar_global"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          {{ menuitem.name }}
        </router-link>
        <a
          v-if="menuitem.type === 'sub'"
          class="current"
          @click="menuClick(menuitem.name)"
        >
          {{ menuitem.name }}
        </a>

        <a
          v-if="menuitem.type === 'lan'"
          class="current"
          @click="menuClick(menuitem.name)"
        >
          <img
            v-if="setFlag(menuitem.children)"
            :src="setFlag(menuitem.children)"
            alt="Flag Image"
          >
          {{ language }}
        </a>

        <!-- Funciones del submenu -->
        <ul
          v-if="menuitem.type === 'sub'"
          id="submenu"
          class="submenu"
        >
          <li
            v-for="childitem in menuitem.children"
            :key="childitem.name"
            :class="{'open-menu': selectedSubMenu == childitem.name}"
          >
            <router-link
              v-if="childitem.type === 'link'"
              :to="{ name: childitem.state, hash: childitem.hash }"
              data-toggle="collapse"
              data-target="#navbar_global"
              aria-controls="navbar_global"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img
                v-if="childitem.image"
                :src="childitem.image"
                alt="Flag Image"
              >
              {{ childitem.name }}
            </router-link>
            <a
              v-if="childitem.type === 'sub'"
              @click="subMenuClick(childitem.name)"
            >
              {{ childitem.name }}
            </a>

            <ul v-if="childitem.type === 'sub'">
              <li
                v-for="subchilditem in childitem.children"
                :key="subchilditem.name"
              >
                <router-link :to="subchilditem.state">
                  {{ subchilditem.name }}
                </router-link>
              </li>
            </ul>

          </li>
        </ul>

        <ul
          v-if="menuitem.type === 'lan'"
          id="submenu"
          class="submenu"
        >
          <li
            v-for="childitem in menuitem.children"
            :key="childitem.name"
            :class="{'open-menu': selectedSubMenu == childitem.name}"
          >
            <a
              v-if="childitem.type === 'lang'"
              @click="setLocale(childitem.state, childitem.name)"
            >
              <img
                v-if="childitem.image"
                :src="childitem.image"
                alt="Flag Image"
              >
              {{ childitem.name }}
            </a>
            <a
              v-if="childitem.type === 'lan'"
              @click="subMenuClick(childitem.name)"
            >
              {{ childitem.name }}
            </a>
          </li>
        </ul>

      </li>
    </ul>

  </nav>
</template>

<script>
import { menus } from './menu-items'

export default {
  data() {
    return {
      menuItems: menus,
      selectedMenu: null,
      selectedSubMenu: null,
      language: (localStorage.getItem('lang') === 'es') ? 'Español' : 'English',
    }
  },
  methods: {

    // Devuelve un valor al pulsar en un enlace del menú de navegación
    menuClick(value) {
      if (this.selectedMenu === value) {
        this.selectedMenu = null
      } else {
        this.selectedMenu = value
      }
    },

    // Devuelve un valor al pulsar en un subenlace del menú de navegación
    subMenuClick(value) {
      if (this.selectedSubMenu === value) {
        this.selectedSubMenu = null
      } else {
        this.selectedSubMenu = value
      }
    },

    // Este método cambia el idioma al pulsar en los enlaces del menú de navegación
    setLocale(locale, name) {
      this.$i18n.locale = locale
      this.language = name
      localStorage.setItem('lang', locale)
      this.$router.go()
    },

    setFlag(array) {
      const found = array.find(element => element.name === this.language)
      return found.image
    },
  },
}
</script>

<style scoped>

  #submenu {
    background-color: rgba(0, 0, 0, 0.8);
  }

</style>
