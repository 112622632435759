<!-- Header Structure -->
<template>
  <header
    class="header-global"
    style="text-transform: uppercase;"
  >
    <nav
      id="navbar-main"
      class="navbar navbar-main fixed-top navbar-expand-lg navbar-transparent navbar-light headroom"
    >
      <div class="container">

        <!-- Logo Limmat to Home -->
        <div id="logo">
          <router-link to="/home">
            <b-img-lazy
              src="/static/limmat-images/logos/limmatLogoLetrasBlancas.png"
              width="150"
              alt="Logo Limmat"
            />
          </router-link>
        </div>

        <!-- Boton para abrir el menu -->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar_global"
          aria-controls="navbar_global"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon" />
        </button>

        <div
          id="navbar_global"
          class="navbar-collapse collapse"
        >
          <div class="navbar-collapse-header">
            <div class="row">

              <!-- Logo Link -->
              <div class="col-6 collapse-brand">
                <div id="logo">
                  <router-link to="/home">
                    <b-img-lazy
                      src="/static/limmat-images/logos/limmatLogoLetrasBlancas.png"
                      width="150"
                      alt="Logo Limmat"
                    />
                  </router-link>
                </div>
              </div>

              <!-- Boton para comprimir el menu -->
              <div class="col-6 collapse-close">
                <button
                  type="button"
                  class="navbar-toggler"
                  data-toggle="collapse"
                  data-target="#navbar_global"
                  aria-controls="navbar_global"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span />
                  <span />
                </button>
              </div>
            </div>
          </div>

          <div class="navbar-nav navbar-nav-hover align-items-lg-center ml-auto">
            <ul class="m-0 p-0">
              <app-menu />
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import Menu from 'Components/Menu/Menu.vue'
import { BImgLazy } from 'bootstrap-vue'

export default {
  name: 'Header',
  components: {
    appMenu: Menu,
    BImgLazy,
  },
  data() {
    return {
      selectedMenu: null,
      selectedSubMenu: null,
    }
  },
}
</script>
