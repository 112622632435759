import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'

import * as VueGoogleMaps from 'vue2-google-maps'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import i18n from '@/lib/i18n'
import App from './App.vue'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

// router
import router from './router'

// include script file
import './lib/loaders/js_loader'

// include all css files
import '@/lib/loaders/css_loader'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Fontawesome Plugin Registration
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBtdO5k6CRntAMJCF-H5uZjTCoSGX95cdk', // Add your here your google map api key
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  components: { App },
  render: h => h(App),
}).$mount('#app')
