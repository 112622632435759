import i18n from '@/lib/i18n'

export const menus = [
  {
    state: 'home',
    name: i18n.t('routes.home'),
    type: 'link',
  },
  {
    state: 'solutions',
    name: i18n.t('routes.solutions'),
    type: 'link',
  },
  {
    state: 'posts',
    name: i18n.t('routes.posts'),
    type: 'link',
  },
  {
    state: 'aboutUs',
    name: i18n.t('routes.aboutUs'),
    type: 'sub',
    children: [
      {
        state: 'aboutUs',
        name: i18n.t('routes.commitments'),
        type: 'link',
        hash: '#id_commitments',
      },
      {
        state: 'aboutUs',
        name: i18n.t('routes.ourTeam'),
        type: 'link',
        hash: '#id_team',
      },
      {
        state: 'aboutUs',
        name: i18n.t('routes.ourValues'),
        type: 'link',
        hash: '#id_values',
      },
      {
        state: 'aboutUs',
        name: i18n.t('routes.joinUp'),
        type: 'link',
        hash: '#id_offers',
      },
    ],
  },
  {
    state: 'contact',
    name: i18n.t('routes.contact'),
    type: 'link',
  },
  {
    state: 'aboutUs',
    name: i18n.t('routes.language'),
    type: 'lan',
    children: [
      {
        state: 'es', name: 'Español', type: 'lang', image: '/static/limmat-images/flags/es.png',
      },
      {
        state: 'en', name: 'English', type: 'lang', image: '/static/limmat-images/flags/en.png',
      },
    ],
  },
]

export default menus
