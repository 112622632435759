import Vue from 'vue'
import Router from 'vue-router'

// routes
import frontendRoutes from './frontend'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    frontendRoutes,
    {
      path: '*',
      redirect: 'error404',
    },
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
        offset: { x: 0, y: 100 },
      }
    }
    return { x: 0, y: 0 }
  },
})
